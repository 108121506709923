import { ADDITIONAL_SUB_TYPES, ImageTypeKeys, ImageTypeSettings } from '../workflows/workflows';
import { GENERIC_STATE_KEYS } from '../root';

export interface IPhotoSeries {
  id: string;
  vehicleType: number;
  vehicle: IVehicle;
  imageTypeSettings: ImageTypeSettings[];
  manualDamageLabellingEnabled: boolean;
  manualDamageLabellingConfirmNoDamages: boolean;
  manualDamageLabellingCustomDamagesEnabled: boolean;
  smartScanImageDownloadEnabled: boolean;
  trackGeolocationEnabled: boolean;
  trackGeolocationRequired: boolean;
  expectedCountryGeolocationRequired: boolean;
  reportSigningRequired: boolean;
  userFeedbackSettings: IUserFeedbackSettings;
  windshieldRepairReplaceDecisionEnabled: boolean;
  windshieldRepairReplaceDecision: WindshieldRepairReplaceDecision | undefined;
  windshieldRepairReplaceBookingUrl: string | undefined;
  smartScanNativeCameraEnabled: boolean;
}

export interface IUserFeedbackSettings {
  satisfactionRatingEnabled: boolean;
  commentEnabled: boolean;
}

export enum WindshieldRepairReplaceDecision {
  Repair = 0,
  Replace = 1,
  Calculating = 3
}

export class PhotoSeries implements IPhotoSeries {
  constructor(photoSeries: IPhotoSeries) {
    this.id = photoSeries.id;
    this.vehicleType = photoSeries.vehicleType;
    this.vehicle = photoSeries.vehicle;
    this.imageTypeSettings = photoSeries.imageTypeSettings;
    this.manualDamageLabellingEnabled = photoSeries.manualDamageLabellingEnabled;
    this.manualDamageLabellingConfirmNoDamages = photoSeries.manualDamageLabellingConfirmNoDamages;
    this.manualDamageLabellingCustomDamagesEnabled = photoSeries.manualDamageLabellingCustomDamagesEnabled;
    this.smartScanImageDownloadEnabled = photoSeries.smartScanImageDownloadEnabled;
    this.trackGeolocationEnabled = photoSeries.trackGeolocationEnabled;
    this.trackGeolocationRequired = photoSeries.trackGeolocationRequired;
    this.expectedCountryGeolocationRequired = photoSeries.expectedCountryGeolocationRequired;
    this.reportSigningRequired = photoSeries.reportSigningRequired;
    this.userFeedbackSettings = photoSeries.userFeedbackSettings;
    this.windshieldRepairReplaceDecisionEnabled = photoSeries.windshieldRepairReplaceDecisionEnabled;
    this.windshieldRepairReplaceDecision = photoSeries.windshieldRepairReplaceDecision;
    this.windshieldRepairReplaceBookingUrl = photoSeries.windshieldRepairReplaceBookingUrl;
    this.smartScanNativeCameraEnabled = photoSeries.smartScanNativeCameraEnabled;
  }

  public id: string;

  public vehicleType: number;

  public vehicle: IVehicle;

  public imageTypeSettings: ImageTypeSettings[];

  public manualDamageLabellingEnabled: boolean;

  public manualDamageLabellingConfirmNoDamages: boolean;

  public manualDamageLabellingCustomDamagesEnabled: boolean;

  public smartScanImageDownloadEnabled: boolean;

  public trackGeolocationEnabled: boolean;

  public trackGeolocationRequired: boolean;

  public expectedCountryGeolocationRequired: boolean;

  public reportSigningRequired: boolean;

  public userFeedbackSettings: IUserFeedbackSettings;

  public windshieldRepairReplaceDecisionEnabled: boolean;

  public windshieldRepairReplaceDecision: WindshieldRepairReplaceDecision | undefined;

  public windshieldRepairReplaceBookingUrl: string | undefined;

  public isManualDamageLabellingEnabled = (imageType: number, imageSubType: number | undefined, customImageTypeId: string | null): boolean => {
    if (!this.manualDamageLabellingEnabled) return false;

    // Do not allow manual marking if additional close ups of damages
    const isAdditionalCloseUps = imageType === ImageTypeKeys.Additional
      && (imageSubType === ADDITIONAL_SUB_TYPES.FIRST_CLOSE_UP || imageSubType === ADDITIONAL_SUB_TYPES.SECOND_CLOSE_UP);

    if (isAdditionalCloseUps) return false;

    const imageTypeSettings = this.imageTypeSettings
      .find((settings) => settings.imageType === imageType && settings.customImageTypeId === customImageTypeId);

    return imageTypeSettings !== undefined
      ? imageTypeSettings.manualDamageLabellingEnabled
      : false;
  };

  public smartScanNativeCameraEnabled: boolean;
}

interface IVehicle {
  id: string,
  registrationNo: string,
  vin: string
}

export const PHOTOSERIES_NAME = 'photoSeries';

export const PHOTOSERIES_STATE_KEYS = {
  ...GENERIC_STATE_KEYS,
  photoSeries: 'photoSeries',
};
