/* eslint-disable no-unused-vars */
import { ActionType } from 'typesafe-actions';

import { IGenericState, GENERIC_STATE_KEYS } from '../root';

export interface ILanguage {
  id: string;
  name: string;
  code: string;
  smartScanTermsAndConditionsFileName?: string;
  smartScanGdprPrivacyPolicyFileName?: string;
}

export interface ITranslation {
  id: string;
  languageId: string;
  categoryName: string;
  categoryType: number;
  translationValues: {
    key: string;
    value: string;
  }[];
  customImageTypeId: string | null;
}

export interface IInternationalizationState extends IGenericState {
  selectedLanguage: ILanguage;
  languages: ILanguage[];
  translations: Record<string, any>;
  termsAndConditions: any;
  gdprPrivacyPolicy: any;
}

export type TInternationalizationActions = ActionType<typeof import('./internationalization.actions')>;

const INTERNATIONALIZATION_NAME = 'internationalization';

enum LANGUAGES_CODES {
  ENGLISH = 'en',
}

const INTERNATIONALIZATION_STATE_KEYS = {
  ...GENERIC_STATE_KEYS,
  languages: 'languages',
  translations: 'translations',
  selectedLanguage: 'selectedLanguage',
  termsAndConditions: 'termsAndConditions',
  gdprPrivacyPolicy: 'gdprPrivacyPolicy',
};

const TRANSLATIONS_VALUES_KEYS = {
  login: {
    login_btn: 'login.login_btn',
    email_field: 'login.email_field',
    password_field: 'login.password_field',
    email_field_required: 'login.email_field_required',
    email_field_invalid_format: 'login.email_field_invalid_format',
    password_field_required: 'login.password_field_required',
    incorrect_email_password: 'login.incorrect_email_password',
    redirecting: 'login.redirecting',
  },
  instructions: {
    flipPhone_message: 'instructions.flipPhone_message',
    followInstruction_message: 'instructions.followInstruction_message',
    skip: 'instructions.skip'
  },
  initiate_scan: {
    useLandscape_message: 'initiate_scan.useLandscape_message',
    startScan_btn: 'initiate_scan.startScan_btn',
  },
  vehicle_scan: {
    angleFront_message: 'vehicle_scan.angleFront_message',
    angleFrontDriver_message: 'vehicle_scan.angleFrontDriver_message',
    angleDriver_message: 'vehicle_scan.angleDriver_message',
    angleBackDriver_message: 'vehicle_scan.angleBackDriver_message',
    angleBack_message: 'vehicle_scan.angleBack_message',
    angleBackPassenger_message: 'vehicle_scan.angleBackPassenger_message',
    anglePassenger_message: 'vehicle_scan.anglePassenger_message',
    angleFrontPassenger_message: 'vehicle_scan.angleFrontPassenger_message',
    imageBlurry_error: 'vehicle_scan.imageBlurry_error',
    wrongPose_error: 'vehicle_scan.wrongPose_error',
    vehicleNotInFrame_error: 'vehicle_scan.vehicleNotInFrame_error',
    noRegistrationNo_error: 'vehicle_scan.noRegistrationNo_error',
    registrationNoMismatch_error: 'vehicle_scan.registrationNoMismatch_error',
    cameraResolution_error: 'vehicle_scan.cameraResolution_error',
    cameraNotReadable_error: 'vehicle_scan.cameraNotReadable_error',
    gdprNotAccepted_error: 'vehicle_scan.gdprNotAccepted_error',
    gdprNotAccepted_error_urltext: 'vehicle_scan.gdprNotAccepted_error_urltext',
    noVin_error: 'vehicle_scan.noVin_error',
    vinMismatch_error: 'vehicle_scan.vinMismatch_error',
    vehicleTooFar_error: 'vehicle_scan.vehicleTooFar_error',
    vehicleNotInTheCenter_error: 'vehicle_scan.vehicleNotInTheCenter_error',
    windshieldNotInFrame_error: 'vehicle_scan.windshieldNotInFrame_error',
    windshieldNotInTheCenter_error: 'vehicle_scan.windshieldNotInTheCenter_error',
    problems_header: 'vehicle_scan.problems_header',
    gdpr_header: 'vehicle_scan.gdpr_header',
    retry_btn: 'vehicle_scan.retry_btn',
    continue_btn: 'vehicle_scan.continue_btn',
    capture_btn: 'vehicle_scan.capture_btn',
    go_back_btn: 'vehicle_scan.go_back_btn',
    process_header: 'vehicle_scan.process_header',
    process_additional_info: 'vehicle_scan.process_additional_info',
    process_exterior_step: 'vehicle_scan.process_exterior_step',
    process_interior_step: 'vehicle_scan.process_interior_step_v2',
    interior_dashboard_message: 'vehicle_scan.interior_dashboard_message',
    interior_trunk_message: 'vehicle_scan.interior_trunk_message',
    interior_front_left_seat_message: 'vehicle_scan.interior_front_left_seat_message',
    interior_back_left_seat_message: 'vehicle_scan.interior_back_left_seat_message',
    interior_front_right_seat_message: 'vehicle_scan.interior_front_right_seat_message',
    interior_back_right_seat_message: 'vehicle_scan.interior_back_right_seat_message',
    process_vin_step: 'vehicle_scan.process_vin_step',
    process_sticker_step: 'vehicle_scan.process_sticker_step',
    process_windshield_step: 'vehicle_scan.process_windshield_step',
    process_additional_step: 'vehicle_scan.process_additional_step',
    process_odometer_step: 'vehicle_scan.process_odometer_step',
    process_keys_step: 'vehicle_scan.process_keys_step',
    process_data_sticker_step: 'vehicle_scan.process_data_sticker_step',
    seriesComplete_header: 'vehicle_scan.seriesComplete_header',
    seriesComplete_publicUser_text: 'vehicle_scan.seriesComplete_publicUser_text',
    seriesComplete_customerEmployee_text: 'vehicle_scan.seriesComplete_customerEmployee_text',
    seriesComplete_back_btn: 'vehicle_scan.seriesComplete_back_btn',
    seriesComplete_restart_btn: 'vehicle_scan.seriesComplete_restart_btn',
    seriesComplete_report_signing_text: 'vehicle_scan.seriesComplete_report_signing_text',
    VIN_message: 'vehicle_scan.VIN_message',
    Sticker_message: 'vehicle_scan.Sticker_message',
    windshield_message: 'vehicle_scan.windshield_message',
    additional_image_message: 'vehicle_scan.additional_image_message',
    odometer_image_message: 'vehicle_scan.odometer_image_message',
    keys_message: 'vehicle_scan.keys_message',
    datasticker_message: 'vehicle_scan.datasticker_message',
    vin_confirmation_title: 'vehicle_scan.vin_confirmation_title',
    vin_confirmation_message: 'vehicle_scan.vin_confirmation_message',
    instr_modal_title_vin: 'vehicle_scan.instr_modal_title_vin',
    instr_modal_title_sticker: 'vehicle_scan.instr_modal_title_sticker',
    instr_modal_title_windshield: 'vehicle_scan.instr_modal_title_windshield',
    instr_modal_title_dashboard: 'vehicle_scan.instr_modal_title_dashboard',
    instr_modal_title_trunk: 'vehicle_scan.instr_modal_title_trunk',
    instr_modal_title_front_left_seat: 'vehicle_scan.instr_modal_title_front_left_seat',
    instr_modal_title_back_left_seat: 'vehicle_scan.instr_modal_title_back_left_seat',
    instr_modal_title_front_right_seat: 'vehicle_scan.instr_modal_title_front_right_seat',
    instr_modal_title_back_right_seat: 'vehicle_scan.instr_modal_title_back_right_seat',
    instr_modal_title_front: 'vehicle_scan.instr_modal_title_front',
    instr_modal_title_front_right: 'vehicle_scan.instr_modal_title_front_right',
    instr_modal_title_left: 'vehicle_scan.instr_modal_title_left',
    instr_modal_title_back_right: 'vehicle_scan.instr_modal_title_back_right',
    instr_modal_title_back: 'vehicle_scan.instr_modal_title_back',
    instr_modal_title_back_left: 'vehicle_scan.instr_modal_title_back_left',
    instr_modal_title_right: 'vehicle_scan.instr_modal_title_right',
    instr_modal_title_front_left: 'vehicle_scan.instr_modal_title_front_left',
    instr_modal_title_bike_front: 'vehicle_scan.instr_modal_title_bike_front',
    instr_modal_title_bike_front_left: 'vehicle_scan.instr_modal_title_bike_front_left',
    instr_modal_title_bike_left: 'vehicle_scan.instr_modal_title_bike_left',
    instr_modal_title_bike_back_left: 'vehicle_scan.instr_modal_title_bike_back_left',
    instr_modal_title_bike_back: 'vehicle_scan.instr_modal_title_bike_back',
    instr_modal_title_bike_back_right: 'vehicle_scan.instr_modal_title_bike_back_right',
    instr_modal_title_bike_right: 'vehicle_scan.instr_modal_title_bike_right',
    instr_modal_title_bike_front_right: 'vehicle_scan.instr_modal_title_bike_front_right',
    instr_modal_title_truck_front: 'vehicle_scan.instr_modal_title_truck_front',
    instr_modal_title_truck_front_left: 'vehicle_scan.instr_modal_title_truck_front_left',
    instr_modal_title_truck_left: 'vehicle_scan.instr_modal_title_truck_left',
    instr_modal_title_truck_back_left: 'vehicle_scan.instr_modal_title_truck_back_left',
    instr_modal_title_truck_back: 'vehicle_scan.instr_modal_title_truck_back',
    instr_modal_title_truck_back_right: 'vehicle_scan.instr_modal_title_truck_back_right',
    instr_modal_title_truck_right: 'vehicle_scan.instr_modal_title_truck_right',
    instr_modal_title_truck_front_right: 'vehicle_scan.instr_modal_title_truck_front_right',
    instr_modal_title_bus_front: 'vehicle_scan.instr_modal_title_bus_front',
    instr_modal_title_bus_front_left: 'vehicle_scan.instr_modal_title_bus_front_left',
    instr_modal_title_bus_left: 'vehicle_scan.instr_modal_title_bus_left',
    instr_modal_title_bus_back_left: 'vehicle_scan.instr_modal_title_bus_back_left',
    instr_modal_title_bus_back: 'vehicle_scan.instr_modal_title_bus_back',
    instr_modal_title_bus_back_right: 'vehicle_scan.instr_modal_title_bus_back_right',
    instr_modal_title_bus_right: 'vehicle_scan.instr_modal_title_bus_right',
    instr_modal_title_bus_front_right: 'vehicle_scan.instr_modal_title_bus_front_right',
    instr_modal_title_trailer_front_left: 'vehicle_scan.instr_modal_title_trailer_front_left',
    instr_modal_title_trailer_back_left: 'vehicle_scan.instr_modal_title_trailer_back_left',
    instr_modal_title_trailer_back_right: 'vehicle_scan.instr_modal_title_trailer_back_right',
    instr_modal_title_trailer_front_right: 'vehicle_scan.instr_modal_title_trailer_front_right',
    instr_modal_tab_vin_windshield: 'vehicle_scan.instr_modal_tab_vin_windshield',
    instr_modal_tab_vin_door_jamb: 'vehicle_scan.instr_modal_tab_vin_door_jamb',
    instr_modal_tab_vin_under_seat: 'vehicle_scan.instr_modal_tab_vin_under_seat',
    instr_modal_tab_vin_passenger_side: 'vehicle_scan.instr_modal_tab_vin_passenger_side',
    instr_modal_tab_vin_engine_room: 'vehicle_scan.instr_modal_tab_vin_engine_room',
    instr_modal_tab_vin_passenger_door_and_seat: 'vehicle_scan.instr_modal_tab_vin_passenger_door_and_seat',
    process_vin_loc_windshield: 'vehicle_scan.process_vin_loc_windshield',
    process_vin_loc_door_jamb: 'vehicle_scan.process_vin_loc_door_jamb',
    process_vin_loc_under_seat: 'vehicle_scan.process_vin_loc_under_seat',
    instr_modal_tab_data_sticker_driver_side_door: 'vehicle_scan.instr_modal_tab_data_sticker_driver_side_door',
    instr_modal_tab_data_sticker_passenger_side_door: 'vehicle_scan.instr_modal_tab_data_sticker_passenger_side_door',
    instr_modal_tab_data_sticker_engine_room: 'vehicle_scan.instr_modal_tab_data_sticker_engine_room',
    instr_modal_title_additional: 'vehicle_scan.instr_modal_title_additional',
    instr_modal_title_odometer: 'vehicle_scan.instr_modal_title_odometer',
    instr_modal_title_keys: 'vehicle_scan.instr_modal_title_keys',
    instr_modal_title_data_sticker: 'vehicle_scan.instr_modal_title_data_sticker',
    instr_modal_instr_text_additional: 'vehicle_scan.instr_modal_instr_text_additional',
    finish_btn: 'vehicle_scan.finish_btn',
    take_additional_photo_btn: 'vehicle_scan.take_additional_photo_btn',
    all_images_are_included: 'vehicle_scan.all_images_are_included',
    no_images_to_add: 'vehicle_scan.no_images_to_add',
    add_additional_images: 'vehicle_scan.add_additional_images',
    add_more_additional_images: 'vehicle_scan.add_more_additional_images',
    image_count: 'vehicle_scan.image_count',
    windshield_from_outside_instruction: 'vehicle_scan.windshield_from_outside_instruction',
    windshield_from_inside_instruction: 'vehicle_scan.windshield_from_inside_instruction',
    windshield_factory_label_instruction: 'vehicle_scan.windshield_factory_label_instruction',
    confirm_photo_message: 'vehicle_scan.confirm_photo_message',
    insertVin_header: 'vehicle_scan.insertVin_header',
    insertSticker_message: 'vehicle_scan.insertSticker_message',
    insertVin_message: 'vehicle_scan.insertVin_message',
    insertVin_placeholder: 'vehicle_scan.insertVin_placeholder',
    vin_field_length: 'vehicle_scan.vin_field_length',
    choose_workflow_btn: 'vehicle_scan.choose_workflow_btn',
    choose_workflow_text: 'vehicle_scan.choose_workflow_text',
    start_scanning_header: 'vehicle_scan.start_scanning_header',
    start_scanning_btn: 'vehicle_scan.start_scanning_btn',
    app_mode: 'vehicle_scan.app_mode',
    fit_vehicle_into_frame: 'vehicle_scan.fit_vehicle_into_frame',
    enter_manually_btn_text: 'vehicle_scan.enter_manually_btn_text',
    retake_btn_text: 'vehicle_scan.retake_btn_text',
    images: 'vehicle_scan.images',
    capture: 'vehicle_scan.capture',
    confirm_btn_text: 'vehicle_scan.confirm_btn_text',
    cancel_btn_text: 'vehicle_scan.cancel_btn_text',
    ai_feedback_modal_heading: 'vehicle_scan.ai_feedback_modal_heading',
    vehicle_captured_heading: 'vehicle_scan.vehicle_captured_heading',
    vehicle_captured_sub_heading: 'vehicle_scan.vehicle_captured_sub_heading',
    vehicle_captured_text: 'vehicle_scan.vehicle_captured_text',
    discard_adding_btn_text: 'vehicle_scan.discard_adding_btn_text',
    finish_adding_btn_text: 'vehicle_scan.finish_adding_btn_text',
    add_more_images_heading_text: 'vehicle_scan.add_more_images_heading_text',
    no_images_yet_text: 'vehicle_scan.no_images_yet_text',
    capture_body_damages_text: 'vehicle_scan.capture_body_damages_text',
    capture_glass_cracks_text: 'vehicle_scan.capture_glass_cracks_text',
    capture_interior_damages_text: 'vehicle_scan.capture_interior_damages_text',
    completed_text: 'vehicle_scan.completed_text',
    one_image_taken_text: 'vehicle_scan.one_image_taken_text',
    images_taken_text: 'vehicle_scan.images_taken_text',
    pose: {
      other: 'vehicle_scan.pose.other',
      front: 'vehicle_scan.pose.front',
      front_left: 'vehicle_scan.pose.front_left',
      left: 'vehicle_scan.pose.left',
      back_left: 'vehicle_scan.pose.back_left',
      back: 'vehicle_scan.pose.back',
      back_right: 'vehicle_scan.pose.back_right',
      right: 'vehicle_scan.pose.right',
      front_right: 'vehicle_scan.pose.front_right'
    },
    warning: {
      snowy: 'vehicle_scan.warning.snowy',
      dirty: 'vehicle_scan.warning.dirty',
      glared: 'vehicle_scan.warning.glared',
      rainy: 'vehicle_scan.warning.rainy'
    },
    blocker: {
      snowy: 'vehicle_scan.blocker.snowy',
      dirty: 'vehicle_scan.blocker.dirty',
      glared: 'vehicle_scan.blocker.glared',
      rainy: 'vehicle_scan.blocker.rainy'
    },
    too_bright_browser_camera_message: 'vehicle_scan.too_bright_browser_camera_message',
    too_bright_browser_camera_btn: 'vehicle_scan.too_bright_browser_camera_btn',
    too_bright_native_camera_message: 'vehicle_scan.too_bright_native_camera_message',
    process_wheels_step: 'vehicle_scan.process_wheels_step',
    instr_modal_title_wheels_front_left: 'vehicle_scan.instr_modal_title_wheels_front_left',
    instr_modal_title_wheels_back_left: 'vehicle_scan.instr_modal_title_wheels_back_left',
    instr_modal_title_wheels_back_right: 'vehicle_scan.instr_modal_title_wheels_back_right',
    instr_modal_title_wheels_front_right: 'vehicle_scan.instr_modal_title_wheels_front_right',
    wheels_front_left_message: 'vehicle_scan.wheels_front_left_message',
    wheels_back_left_message: 'vehicle_scan.wheels_back_left_message',
    wheels_back_right_message: 'vehicle_scan.wheels_back_right_message',
    wheels_front_right_message: 'vehicle_scan.wheels_front_right_message',
  },
  burger_menu: {
    language_field: 'burger_menu.language_field',
    sign_out_btn: 'burger_menu.sign_out_btn',
    exit_btn: 'burger_menu.exit_btn',
    save_and_close: 'burger_menu.save_and_close',
  },
  validation_wrapper: {
    close_btn: 'validation_wrapper.close_btn',
    cameraAccessDenied_header: 'validation_wrapper.cameraAccessDenied_header',
    cameraAccessDenied_text: 'validation_wrapper.cameraAccessDenied_text',
    geolocationAccessDenied_header: 'validation_wrapper.geolocationAccessDenied_header',
    geolocationAccessDenied_text: 'validation_wrapper.geolocationAccessDenied_text',
    useMobileTitle: 'validation_wrapper.useMobileTitle',
    useMobileDescription: 'validation_wrapper.useMobileDescription',
    notification_text: 'validation_wrapper.notification_text',
    question_text: 'validation_wrapper.question_text',
    cameraLink_text: 'validation_wrapper.cameraLink_text',
    geolocationLink_text: 'validation_wrapper.geolocationLink_text',
    linkCopied_text: 'validation_wrapper.linkCopied_text',
    offline_msg: 'validation_wrapper.offline_msg',
    online_msg: 'validation_wrapper.online_msg',
    unsupportedBrowser_header_text: 'validation_wrapper.unsupportedBrowser_header_text',
    unsupportedBrowserLink_text: 'validation_wrapper.unsupportedBrowserLink_text',
    continue_anyways_btn: 'validation_wrapper.continue_anyways_btn',
    supported_browsers_description_text: 'validation_wrapper.supported_browsers_description_text',
    supported_browsers_text: 'validation_wrapper.supported_browsers_text',
    copy_link_instruction_text: 'validation_wrapper.copy_link_instruction_text',
    copy_link_text: 'validation_wrapper.copy_link_text',
    unavailable_service_header_text: 'validation_wrapper.unavailable_service_header_text',
    unavailable_service_text: 'validation_wrapper.unavailable_service_text'
  },
  photoseries: {
    welcome_heading_text: 'photoseries.welcome_heading_text',
    welcome_subheading_text: 'photoseries.welcome_subheading_text',
    guideline_one: 'photoseries.guideline_one',
    guideline_two: 'photoseries.guideline_two',
    start_btn_text: 'photoseries.start_btn_text',
    session_expired: 'photoseries.session_expired',
    restart: 'photoseries.restart',
  },
  error: {
    title: 'error.title',
    body: 'error.body',
  },
  server_error: {
    title: 'server_error.title',
    body: 'server_error.body',
  },
  timeout_error: {
    title: 'timeout_error.title',
    body: 'timeout_error.body',
  },
  user_feedback: {
    how_easy: 'user_feedback.how_easy',
    choose_rating: 'user_feedback.choose_rating',
    leave_comment: 'user_feedback.leave_comment',
    submit: 'user_feedback.submit',
    input_label: 'user_feedback.input_label',
    title_experience: 'user_feedback.title_experience',
    title_question: 'user_feedback.title_question',
    title_thankyou: 'user_feedback.title_thankyou',
    title_download_images: 'user_feedback.title_download_images',
    title_redirect: 'user_feedback.title_redirect',
    subtitle_question: 'user_feedback.subtitle_question',
    btn_send: 'user_feedback.btn_send',
    btn_menu: 'user_feedback.btn_menu',
    btn_scanAnother: 'user_feedback.btn_scanAnother',
    btn_download_images: 'user_feedback.btn_download_images',
    btn_no_thanks: 'user_feedback.btn_no_thanks',
    title_download_will_start_shorty: 'user_feedback.title_download_will_start_shorty',
    inspection_summary: 'user_feedback.inspection_summary',
    windshield_inspection_summary_loading: 'user_feedback.windshield_inspection_summary_loading',
    windshield_inspection_summary_repair: 'user_feedback.windshield_inspection_summary_repair',
    windshield_inspection_summary_replace: 'user_feedback.windshield_inspection_summary_replace',
    windshield_inspection_summary_information: 'user_feedback.windshield_inspection_summary_information',
    inspection_summary_book_a_time: 'user_feedback.inspection_summary_book_a_time'
  },
  workflow_selection: {
    app_mode: 'workflow_selection.app_mode',
    choose_workflow_btn: 'workflow_selection.choose_workflow_btn',
    choose_workflow_text: 'workflow_selection.choose_workflow_text',
    start_scanning_header: 'workflow_selection.start_scanning_header',
    start_scanning_btn: 'workflow_selection.start_scanning_btn',
  },
  terms_of_service: {
    terms_of_service: 'terms_of_service.terms_of_service',
    gdpr_agreement_confirmation: 'terms_of_service.gdpr_agreement_confirmation',
    terms_and_conditions_agreement_confirmation: 'terms_of_service.terms_and_conditions_agreement_confirmation',
    allRightsReserved: 'terms_of_service.allRightsReserved',
    failed_load: 'terms_of_service.failed_load',
    terms_and_conditions_msg_one: 'terms_of_service.terms_and_conditions_msg_one',
    terms_and_conditions_msg_two: 'terms_of_service.terms_and_conditions_msg_two',
    gdpr_msg_one: 'terms_of_service.gdpr_msg_one',
    gdpr_msg_two: 'terms_of_service.gdpr_msg_two',
    gdpr_msg_three: 'terms_of_service.gdpr_msg_three',
  },
  process_flow: {
    vin: {
      heading: 'process_flow.vin_process_flow.heading',
      sub_heading: 'process_flow.vin_process_flow.sub_heading_v2',
      button_text: 'process_flow.vin_process_flow.button_text',
    },
    inspection_sticker: {
      heading: 'process_flow.inspection_sticker_process_flow.heading',
      sub_heading: 'process_flow.inspection_sticker_process_flow.sub_heading',
      message: 'process_flow.inspection_sticker_process_flow_message',
      button_text: 'process_flow.inspection_sticker_process_flow.button_text',
    },
    keys: {
      heading: 'process_flow.keys_process_flow.heading',
      sub_heading: 'process_flow.keys_process_flow.sub_heading',
      message: 'process_flow.keys_process_flow_message',
      button_text: 'process_flow.keys_process_flow.button_text',
    },
    exterior: {
      heading: 'process_flow.exterior_process_flow.heading',
      sub_heading: 'process_flow.exterior_process_flow.sub_heading',
      message: 'process_flow.exterior_process_flow_message',
      button_text: 'process_flow.exterior_process_flow.button_text',
    },
    interior: {
      heading: 'process_flow.interior_process_flow.heading_v2',
      sub_heading: 'process_flow.interior_process_flow.sub_heading_v2',
      message: 'process_flow.interior_process_flow_message',
      button_text: 'process_flow.interior_process_flow.button_text_v2',
    },
    odometer: {
      heading: 'process_flow.odometer_process_flow.heading',
      sub_heading: 'process_flow.odometer_process_flow.sub_heading',
      message: 'process_flow.odometer_process_flow_message',
      button_text: 'process_flow.odometer_process_flow.button_text',
    },
    windshield: {
      heading: 'process_flow.windshield_process_flow.heading',
      sub_heading: 'process_flow.windshield_process_flow.sub_heading',
      message: 'process_flow.windshield_process_flow_message',
      button_text: 'process_flow.windshield_process_flow.button_text',
    },
    additional: {
      heading: 'process_flow.additional_process_flow.heading',
      sub_heading: 'process_flow.additional_process_flow.sub_heading',
      message: 'process_flow.additional_process_flow_message',
      button_text: 'process_flow.additional_process_flow.button_text',
    },
    dataSticker: {
      heading: 'process_flow.data_sticker_process_flow.heading',
      sub_heading: 'process_flow.data_sticker_process_flow.sub_heading',
      message: 'process_flow.data_sticker_process_flow_message',
      button_text: 'process_flow.data_sticker_process_flow.button_text',
    },
    wheels: {
      heading: 'process_flow.wheels_process_flow.heading',
      sub_heading: 'process_flow.wheels_process_flow.sub_heading',
      message: 'process_flow.wheels_process_flow.message',
      button_text: 'process_flow.wheels_process_flow.button_text',
    }
  },
  accessibility: {
    scroll_bottom_text: 'accessibility.scroll_bottom_text',
    open_settings_view_btn_text: 'accessibility.open_settings_view_btn_text',
    close_settings_view_btn_text: 'accessibility.close_settings_view_btn_text',
    capture_image_btn_text: 'accessibility.capture_image_btn_text',
    confirm_image_btn_text: 'accessibility.confirm_image_btn_text',
    next_btn_text: 'accessibility.next_btn_text',
    settings_view_title: 'accessibility.settings_view_title'
  },
  damage_labelling: {
    drag_screen_instructions: 'damage_labelling.drag_screen_instructions',
    remove_damage_instructions: 'damage_labelling.remove_damage_instructions',
    mark_chip_instructions: 'damage_labelling.mark_chip_instructions',
    body_part: {
      unknown: 'damage_labelling.body_part.unknown',
      windshield: 'damage_labelling.body_part.windshield',
      tailgateglass: 'damage_labelling.body_part.tailgateglass',
      fender: 'damage_labelling.body_part.fender',
      backdoor: 'damage_labelling.body_part.backdoor',
      frontdoor: 'damage_labelling.body_part.frontdoor',
      mirror: 'damage_labelling.body_part.mirror',
      doorglass: 'damage_labelling.body_part.doorglass',
      hood: 'damage_labelling.body_part.hood',
      trunk: 'damage_labelling.body_part.trunk',
      licenseplate: 'damage_labelling.body_part.licenseplate',
      frontbumper: 'damage_labelling.body_part.frontbumper',
      rearbumper: 'damage_labelling.body_part.rearbumper',
      light: 'damage_labelling.body_part.light',
      grille: 'damage_labelling.body_part.grille',
      wheel: 'damage_labelling.body_part.wheel',
      body: 'damage_labelling.body_part.body',
      sideskirt: 'damage_labelling.body_part.sideskirt',
    },
    damage_type: {
      dent: 'damage_labelling.damage_type.dent',
      lampbroken: 'damage_labelling.damage_type.lamp_broken',
      paintdamage: 'damage_labelling.damage_type.paint_damage',
      rust: 'damage_labelling.damage_type.rust',
      scrape: 'damage_labelling.damage_type.scrape',
      scratch: 'damage_labelling.damage_type.scratch',
      chip: 'damage_labelling.damage_type.chip',
      crack: 'damage_labelling.damage_type.crack',
      otherdamage: 'damage_labelling.damage_type.other_damage',
      misaligned: 'damage_labelling.damage_type.misaligned',
      missingpart: 'damage_labelling.damage_type.missing_part'
    },
    confirm_damage_removal_question: 'damage_labelling.confirm_damage_removal_question',
    confirm_no_damages_question: 'damage_labelling.confirm_no_damages_question',
    confirm_damage_removal_yes: 'damage_labelling.confirm_damage_removal_yes',
    confirm_damage_removal_no: 'damage_labelling.confirm_damage_removal_no',
    capture_damage_title: 'damage_labelling.capture_damage_title',
    capture_damage_description: 'damage_labelling.capture_damage_description',
    capture_description: 'damage_labelling.capture_description',
    capture_button_text: 'damage_labelling.capture_button_text',
    damage_type_text: 'damage_labelling.damage_type'
  }
};

export { INTERNATIONALIZATION_NAME, INTERNATIONALIZATION_STATE_KEYS, TRANSLATIONS_VALUES_KEYS, LANGUAGES_CODES };
